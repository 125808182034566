import * as React from "react"
import { graphql, Link } from "gatsby"
import { Layout } from "../../../components/layout"
import { Seo } from "../../../components/seo"
import { Breadcrumb, Col, Container, Row } from "react-bootstrap"
import CollectionProductsListing from "../../../components/CollectionProductsListing"
import { ArrowLeft } from "react-bootstrap-icons"
import { getProductTitle } from "../../../components/ProductCard"
import { getSrc } from "gatsby-plugin-image"

export default function Collection({ data: {collection} }) {
  return (
    <Layout>
      <Container className="py-4">
        <Row>
          <Col className="mb-4">
            <Breadcrumb>
              <li className="breadcrumb-item">
                <Link to="/producten/" className="text-primary fw-bold"><ArrowLeft/> Producten</Link>
              </li>
              <li className="breadcrumb-item active">
                {collection.title}
              </li>
            </Breadcrumb>
          </Col>
        </Row>
        <h1 className="display-2 fw-bold text-center mb-4">{collection.title}</h1>
        <CollectionProductsListing collection={collection} products={collection.products} showAll={true} />
      </Container>
    </Layout>
  )
}


export const query = graphql`
  query ($id: String!) {
    collection: shopifyCollection(id: {eq: $id}) {
      id
      title
      products {
        ...ProductCard
      }
    }
  }
`

export const Head = ({ data: {collection} }) => {
  return (
    <Seo
      title={collection.title}
    />
  )
}
